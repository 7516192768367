import React, { useEffect, useState } from "react";

import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import jiniassistApi from "../../API/jiniassistApi";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../views/Buttons/Button";
import Loader from "../../views/loader/Loader";
import { UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

const JiniAssist = () => {
  UpdateSidebar("Jini Assist");
  const [jaScript, setJAScript] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { company_id } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    fetchApiKey();
  }, []);

  const fetchApiKey = async () => {
    setIsLoading(true);
    let fetch_api_key = await jiniassistApi.get(
      `/jiniassist/getapikey/${company_id}`
    );
    if (fetch_api_key && fetch_api_key.data.status === 1) {
      setJAScript(
        `<!-- Start to Jini Assist script  --><script>(function () { var jiniAssist_API=jiniAssist_API||{}, jini_LoadStart=new Date();let s = document.createElement('script');s.type = 'text/javascript';s.async = true;s.id='jini-script-id';s.src = 'https://admin.bookingjini.com/v3/jiniAssist/index.js?api_key=${fetch_api_key.data.data.api_key}';var x = document.getElementsByTagName('script')[0];s.charset='UTF-8';s.setAttribute('Access-Control-Allow-Origin','*');x.parentNode.insertBefore(s, x);})();</script><!-- End of Jini Assist script-->`
      );
      setIsLoading(false);
    }
  };

  const copyJAScript = () => {
    toast.success("Script Copied Successfully");
    navigator.clipboard.writeText(jaScript);
  };

  return (
    <>
      <Breadcrumbs />
      <div className="hr--line"></div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="script-container">
            <div className="script-wrapper">
              <div className="script-content">{jaScript}</div>
            </div>
          </div>
          <div className="d-flex mt-4">
            <Button handleClick={copyJAScript}>Copy</Button>
          </div>
        </>
      )}
    </>
  );
};

export default JiniAssist;
