import { CHOOSE_PROPERTY, GET_PROPERTIES } from "../actionTypes";

export const AllProperties = (data: any) => {
  return {
    type: GET_PROPERTIES,
    payload: data,
  };
};

export const CurrentProperty = (prop: any) => {
  return {
    type: CHOOSE_PROPERTY,
    payload: prop,
  };
};
