import React, { useState } from "react";
// import InputPasswordField from '../../views/inputtextfield/InputPasswordField';
// import InputTextField from '../../views/inputtextfield/InputTextField';
import Sidebar_Menu from "./SidebarMenu.json";
import SidebarItem from "./SidebarItem";
import companyLogo from "../../assets/img/company_logo.png";
import {
  DashboardIcon,
  InteractIcon,
  JiniAssistIcon,
  LeadsIcon,
  TrainingIcon,
} from "../../assets/img/sidebarIcon";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { ActiveSidebarItem } from "../../redux/actions/SidebarAction";
export interface IMenuItemsProps {
  label: string;
  icon: string;
  dropdown?: string[];
  showDropdown?: boolean;
}

const AppSidebar: React.FC = () => {
  const dispatch = useDispatch();

  //   const [menuItems, setMenuItems] = useState([
  //     {
  //       label: "Dashboard",
  //       icon: DashboardIcon,
  //       showDropdown: false,
  //       path: "/",
  //     },

  //     {
  //       label: "JiniAssist Script",
  //       icon: JiniAssistIcon,
  //       showDropdown: false,
  //       path: "/JiniAssist",
  //     },
  //     {
  //       label: "Leads",
  //       icon: LeadsIcon,
  //       showDropdown: false,
  //       path: "/Leads",
  //     },
  //     {
  //       label: "Interaction",
  //       icon: InteractIcon,
  //       showDropdown: false,
  //       path: "/Interaction",
  //     },
  //     {
  //       label: "Training",
  //       icon: TrainingIcon,
  //       showDropdown: false,
  //       path: "/Training",
  //     },
  //   ]);

  const [activeSidebarItem, setActiveSidebarItem] = useState("Dashboard");

  // const [emailInput, setEmailInput] = useState("");
  // const [passwordInput, setPasswordInput] = useState("");

  // const handleSidebarItemClick: (item: IMenuItemsProps) => void = (item) => {
  //     const newMenuItems = [...menuItems];
  //     newMenuItems.forEach(menuItem => {
  //         if (menuItem.label === item.label) {
  //             menuItem.showDropdown = !menuItem.showDropdown;
  //         } else {
  //             menuItem.showDropdown = false;
  //         }
  //     })
  //     setMenuItems(newMenuItems);
  //     setActiveSidebarItem(item.label)
  // }

  const handleSidebarItemClick: (item: IMenuItemsProps) => void = (item) => {
    dispatch(ActiveSidebarItem(item.label));
    if (item.label === active_sidebar_item) {
      item.showDropdown = !item.showDropdown;
    }
  };

  const { active_sidebar_item } = useSelector(
    (state: RootState) => state.sidebar
  );

  return (
    <>
      <div className="sidebar-main-wrapper">
        <div className="sidebar-wrapper sidebar-position">
          <div className="company__logo__wrapper">
            <Link to="/">
              <div className="company--logo"></div>
            </Link>
          </div>
          <ul className="sidebar__list__wrapper">
            {Sidebar_Menu.map((item: any, index: number) => {
              return (
                <SidebarItem
                  key={index}
                  handleClick={() => handleSidebarItemClick(item)}
                  isNew={item.isNew}
                  href={item.href}
                  label={item.label}
                  icon={item.icon}
                  dropdown={item.dropdown}
                  showDropdown={item.showDropdown}
                  active={active_sidebar_item === item.label}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppSidebar;
