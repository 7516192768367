import React, { useState } from "react";
import InputTextField from "../../views/inputfield/InputTextField";
import InputPasswordField from "../../views/inputfield/InputPasswordField";
import LogInButton from "../../views/Buttons/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { validateEmail, validateMobile } from "../../UtilityFunctions";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import Loader from "../../views/loader/Loader";
import jiniassistApi from "../../API/jiniassistApi";
import { browserName } from "react-device-detect";
import { LoginSuccess } from "../../redux/actions/LoginAction";
import Button from "../../views/Buttons/Button";
import {
  AllProperties,
  CurrentProperty,
} from "../../redux/actions/PropertiesAction";
import console from "console";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<any>("");
  const [password, setPassword] = useState<any>("");

  const [userNameValidMessage, setUserNameValidMessage] = useState<any>("");
  const [passwordValidMessage, setPasswordValidMessage] = useState<any>("");
  const [isloading, setIsLoading] = useState(false);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    if (
      validateEmail(e.target.value) ||
      validateMobile(e.target.value) ||
      !e.target.value
    ) {
      setUserNameValidMessage("");
    } else {
      setUserNameValidMessage("Invalid email or mobile");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 4 && e.target.value) {
      setPasswordValidMessage("Invalid password");
    } else {
      setPasswordValidMessage(" ");
    }
  };

  const handleSubmit = async () => {
    if (!username) {
      toast.error("Enter your email or mobile");
    } else if (!password) {
      toast.error("Please enter your password");
    } else {
      setIsLoading(true);
      let response = await kernelApi.post("/admin/auth", {
        email: username,
        password: password,
        browser: browserName,
      });
      // console.log("response", response);
      setTimeout(async () => {
        if (response.data.status === 1) {
          dispatch(LoginSuccess(response.data));
          const { company_id, auth_token } = response.data ?? {};
          getAllHotels(company_id, auth_token);
          navigate("/");
        } else if (response.data.status === 2) {
          toast.error(response.data.message);
        } else {
          toast.error("Invalid Credentials");
        }
        setUsername("");
        setPassword("");
        setIsLoading(false);
      }, 600);
    }
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const getAllHotels = async (company_id: any, auth_token: any) => {
    setIsLoading(true);
    let get_all_hotels = await kernelApi.get(
      `/hotel_admin/get_hotel_list/${company_id}`,
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (get_all_hotels && get_all_hotels.data.status === 1) {
      dispatch(AllProperties(get_all_hotels?.data?.data));
      dispatch(CurrentProperty(get_all_hotels?.data?.data[0]));

      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login__header">Log in</div>
      <div className="user__id">
        <InputTextField
          value={username}
          helperText={userNameValidMessage}
          label="Email or Mobile"
          handleChange={handleUsernameChange}
          onKeyPress={onKeyEnter}
          handleFocus={(e: any) => {
            if (!e.target.value) setUserNameValidMessage("");
          }}
        />
      </div>
      <div className="">
        <InputPasswordField
          value={password}
          helperText={passwordValidMessage}
          onEnterKey={onKeyEnter}
          handleFocus={(e: any) => {
            if (!e.target.value) setPasswordValidMessage("");
          }}
          handleChange={handlePasswordChange}
        />
      </div>

      <Button className="login--btn" handleClick={() => handleSubmit()}>
        Login
      </Button>
    </div>
    // </div>
  );
};

export default LoginForm;
