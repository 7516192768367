import { useState, useEffect } from "react";
import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import downarrow from "../../assets/img/downarrow.png";
import Pagination from "../pagination/Pagination";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import InputSelectField from "../../views/inputfield/InputSelectField";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import moment from "moment";
import jiniassistApi from "../../API/jiniassistApi";
import Button from "../../views/Buttons/Button";
import Loader from "../../views/loader/Loader";
import JiniEndPoint from "../../API/JiniEndPoint";
import { UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import {
  AllProperties,
  CurrentProperty,
} from "../../redux/actions/PropertiesAction";

const Leads = () => {
  UpdateSidebar("Leads");
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [allHotels, setAllHotels] = useState<any>([]);
  const [selectedHotel, setSelectedHotel] = useState({
    hotel_id: "",
    hotel_name: "",
  });

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);

  const [allLeads, setAllLeads] = useState<any>([]);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllHotels();
  }, []);

  const dispatch = useDispatch();
  const getAllHotels = async () => {
    setIsLoading(true);
    let get_all_hotels = await kernelApi.get(
      `/hotel_admin/get_hotel_list/${company_id}`,
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (get_all_hotels && get_all_hotels.data.status === 1) {
      setSelectedHotel({
        hotel_id: get_all_hotels.data.data[0].hotel_id,
        hotel_name: get_all_hotels.data.data[0].hotel_name,
      });
      dispatch(AllProperties(get_all_hotels?.data?.data));
      dispatch(CurrentProperty(get_all_hotels?.data?.data[0]));
      setAllHotels(get_all_hotels.data.data);
      setIsLoading(false);
    }
  };

  const selectHotel = (e: any) => {
    for (let hotel_data of allHotels) {
      if (hotel_data.hotel_id == e) {
        setSelectedHotel({ hotel_id: e, hotel_name: hotel_data.hotel_name });
      }
    }
  };

  const getLeads = async () => {
    setIsLoading(true);
    let from_date = startDate.format("YYYY-MM-DD");
    let to_date = endDate.format("YYYY-MM-DD");

    let get_all_leads = await jiniassistApi.get(
      `/jiniassist/getjiniassist-Details/${selectedHotel.hotel_id}/${from_date}/${to_date}`,
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (get_all_leads && get_all_leads.data.status === 1) {
      setAllLeads(get_all_leads.data.data);
      setDisplayMessage(false);
      setIsLoading(false);
    } else {
      setDisplayMessage(true);
      setIsLoading(false);
    }
  };

  console.log(allLeads);
  // Excel Download
  const leadsToExcel = () => {
    let leads_data = allLeads.map((lead: any) => {
      return {
        user_name: lead.name,
        hotel_name: selectedHotel.hotel_name,
        email: lead.email,
        phone: lead.mobile,
      };
    });
    var arrStr = JSON.stringify(leads_data);
    var url = `${JiniEndPoint()}/leads-data/download/${arrStr}`;
    console.log(url);
    window.location.assign(url);
  };
  return (
    <>
      <Breadcrumbs />
      <div className="hr--line"></div>
      {/* <SidebarItemHeader styles="nav-wrapper"> */}
      <div className="leads-nav-container mt-4">
        <div className="leads-drop-continer">
          <div className="d-flex add__background">
            <InputSelectField
              label="Select Hotel"
              value={selectedHotel.hotel_id}
              handleChange={(e) => selectHotel(e.target.value)}
              values={allHotels.map((data: any) => {
                return data.hotel_id;
              })}
              options={allHotels.map((data: any) => {
                return data.hotel_name;
              })}
            />
          </div>

          {/* <div
            className="leads__select__date__wrapper"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>

            <div className="date__label">
              {!startDate && !endDate
                ? "Select Dates"
                : (startDate ? startDate.format("DD MMM YYYY") : "") +
                  " - " +
                  (endDate ? endDate.format("DD MMM YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
            />
          </div> */}

          <div style={{ width: "320px" }}>
            <div
              className="leads__select__date__wrapper my-4 pro-sold"
              onClick={() => setFocusedInput("startDate")}
            >
              <div className="date__picker__icon">
                <i className="bi bi-calendar"></i>
              </div>

              <div className="date__label">
                {!startDate && !endDate
                  ? "Select Date Range"
                  : (startDate ? moment(startDate).format("DD MMM YYYY") : "") +
                    " - " +
                    (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
              </div>
              <CustomDateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
              />
            </div>
          </div>
        </div>
        {/* <CustomButton
            handleClick={getLeads}
            styles="btn btn-lg  download-btn ">
            Search
          </CustomButton>

          <CustomButton
            handleClick={getLeads}
            styles="btn btn-lg  download-btn ">
            Download
          </CustomButton> */}
        <div className="leads-buttons-container">
          <Button icon="bi bi-info-circle" handleClick={getLeads}>
            Search
          </Button>
          {allLeads.length > 0 && (
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                leadsToExcel();
              }}
            >
              Download
            </Button>
          )}
        </div>
      </div>
      {/* </SidebarItemHeader> */}
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader />
        </div>
      ) : allLeads.length > 0 ? (
        <div className="list-view-table-wrapper mt-4">
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>#</th>
                <th>User Name </th>
                <th>Hotel Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {allLeads
                .slice(
                  selectPageSize * (currentPageNo - 1),
                  selectPageSize * currentPageNo
                )
                .map((item: any, id: number) => (
                  <tr key={id} className="listview__table__data">
                    <td>{id + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <div className="email__ph">
                        {selectedHotel.hotel_name}
                      </div>
                    </td>
                    <td>
                      <div className="email__ph">{item.email} </div>
                    </td>

                    <td>{item.mobile}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            setCurrentPageNo={setCurrentPageNo}
            data={allLeads}
            setSelectPageSize={setSelectPageSize}
            currentPageNo={currentPageNo}
            selectPageSize={selectPageSize}
          />
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>No Leads found !</div>
        </div>
      )}
    </>
  );
};

export default Leads;
