import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import back from "../../assets/img/back.png";
import next from "../../assets/img/next.png";
import InputSelectField from "../../views/inputfield/InputSelectField";

export interface Props {
  setCurrentPageNo: Dispatch<SetStateAction<number>>;
  setSelectPageSize: Dispatch<SetStateAction<number>>;
  data: any[];
  selectPageSize: any;
  currentPageNo: any;
}

export const Pagination: React.FC<Props> = ({
  data,
  setCurrentPageNo,
  setSelectPageSize,
  selectPageSize,
  currentPageNo,
}) => {
  // const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  const pageSize = [10, 15, 20, 25, 30];

  useEffect(() => {
    if (data?.length > 0) {
      setNoOfPages(Math.ceil(data?.length / selectPageSize));
    }
  }, [selectPageSize, data]);

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };
  return (
    <div className="pagination-container">
      <div className="pagination__left">
        <div className="pagination__dropdown">
          <InputSelectField
            label="Select"
            value={selectPageSize}
            options={pageSize}
            handleChange={(e) => {
              setSelectPageSize(e.target.value);
              setNoOfPages(Math.ceil(data.length / e.target.value));
              setCurrentPageNo(1);
            }}
          />
        </div>
        <ul>
          {Array(noOfPages)
            .fill(0)
            .map((p, i) => {
              return (
                <li
                  key={i}
                  onClick={() => setCurrentPageNo(i + 1)}
                  className={`${
                    i + 1 === currentPageNo
                      ? "active__page"
                      : "showing__page__number"
                  }`}
                >
                  {i + 1}
                </li>
              );
            })}
        </ul>
      </div>
      <div className="pagination__right">
        <div className="arrow__container me-3" onClick={leftArrowClick}>
          <i className="bi bi-chevron-left font--weight"></i>
        </div>
        <div className="arrow__container" onClick={rightArrowClick}>
          <i className="bi bi-chevron-right font--weight"></i>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
