import Button from "../../views/Buttons/Button";
import React, { useEffect, useState } from "react";
import FormButtons from "../../views/Buttons/FormButtons";
import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import InputSelectField from "../../views/inputfield/InputSelectField";
import jiniassistApi from "../../API/jiniassistApi";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import CustomButton from "../../views/Buttons/CustomButton";
import { toast } from "react-toastify";
// import { UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

const Training = () => {
  // UpdateSidebar("Training");
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [allQuestions, setAllQuestions] = useState<any>([]);
  const [questionId, setQuestionId] = useState(-1);

  const [allAnswers, setAllAnswers] = useState<any>([]);
  const [answer, setAnswer] = useState("");

  const [addQuestion, setAddQuestion] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);

  useEffect(() => {
    getAllTrainingData();
  }, []);

  useEffect(() => {
    getAllTrainingData();
  }, [updated, current_property.hotel_id]);

  const getAllTrainingData = async () => {
    setLoader(true); // set loader to true
    let get_all_training_data = await jiniassistApi.get(
      `/retrive-data/${company_id}/${current_property.hotel_id}`
    );

    if (get_all_training_data && get_all_training_data.data.status === 1) {
      setAllQuestions(get_all_training_data.data.questions);
      setAllAnswers(get_all_training_data.data.data);

      if (questionId === -1) {
        setQuestionId(get_all_training_data.data.questions[0].questions_id);

        setAnswer(
          get_all_training_data.data.data.filter((el: any) => {
            return (
              el.questions_id ===
              get_all_training_data.data.questions[0].questions_id
            );
          })[0]?.answers
        );
      } else {
        setQuestionId(questionId);
        setAnswer(
          get_all_training_data.data.data.filter((el: any) => {
            return el.questions_id === questionId;
          })[0]?.answers
        );
      }
      // setQuestion(get_all_training_data.data.questions[0].questions_id);
      // setAnswer(get_all_training_data.data.data[0].answers);
      setLoader(false);
    }
  };

  const selectQuestion = (e: any) => {
    setAnswer("");
    setQuestionId(e);
    if (allAnswers.length > 0) {
      for (let i = 0; i < allAnswers.length; i++) {
        if (e == allAnswers[i].questions_id) {
          setAnswer(allAnswers[i].answers);
        }
      }
    }
  };

  const updatedAnswer = (e: any) => {
    setAnswer(e);
  };

  const saveAnswer = async () => {
    setLoader(true);

    if (answer.length > 0) {
      let update_answer = await jiniassistApi.post(
        "/save-answers",
        {
          company_id: company_id,
          hotel_id: current_property.hotel_id,
          questions_id: questionId,
          answers: answer,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (update_answer.data.status === 1) {
        toast.success(update_answer.data.message);
        setLoader(false);
        setUpdated(!updated);
      } else {
        toast.success(update_answer.data.message);
        setLoader(false);
        // setUpdated(!updated);
      }
    } else {
      toast.error("Please enter answer");
      setLoader(false);
    }
  };

  const saveQuestion = async () => {
    setLoader(true);
    if (addQuestion.length > 0) {
      let add_question = await jiniassistApi.post(
        "/add-question",
        {
          questions: addQuestion,
          hotel_id: current_property.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (add_question.data.status === 1) {
        toast.success(add_question.data.message);
        setLoader(false);
        setUpdated(!updated);
        setAddQuestion("");
      } else {
        toast.success(add_question.data.message);
        setLoader(false);
      }
    } else {
      toast.error("Please enter question");
      setLoader(false);
    }
  };

  return (
    <>
      <Breadcrumbs />
      <div className="hr--line"></div>
      <div className="query-container">
        <div className="query-form">
          <div className="form-wrapper">
            <div className="form-label">Select your question</div>
            {/* <select className="drop-box">
              <option value={1}>option 1</option>
              <option value={1}>option 2</option>
              <option value={1}>option 3</option>
              <option value={1}>option 4</option>
            </select> */}
            <InputSelectField
              label="Select your question"
              value={questionId}
              handleChange={(e) => selectQuestion(e.target.value)}
              values={allQuestions.map((data: any) => {
                return data.questions_id;
              })}
              options={allQuestions.map((data: any) => {
                return data.questions;
              })}
            />
            <textarea
              className="text-area"
              placeholder="Submit Your answer...."
              value={answer}
              onChange={(e) => updatedAnswer(e.target.value)}
            />
            {/* <FormButtons
              InputType="submit"
              InputValue="Save"
              styles="btn btn-lg submit-btn"
            /> */}
            <Button className="mt-3" handleClick={saveAnswer} loader={loader}>
              Save
            </Button>
          </div>
        </div>
        <div className="query-form">
          <div className="form-wrapper">
            <div className="form-label">Add new question</div>
            <textarea
              className="text-area-short"
              placeholder="Type your question...."
              value={addQuestion}
              onChange={(e) => setAddQuestion(e.target.value)}
            />
            <Button className="mt-3" handleClick={saveQuestion} loader={loader}>
              Add
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Training;
