import React from "react";

// import react-dates datepicker
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

export interface IDateRangePickerProps {
  startDate: any;
  endDate: any;
  setStartDate: React.Dispatch<React.SetStateAction<any>>;
  setEndDate: React.Dispatch<React.SetStateAction<any>>;
  focusedInput: any;
  setFocusedInput: React.Dispatch<React.SetStateAction<any>>;
  preventPastDates?: boolean;
}

const CustomDateRangePicker: React.FC<IDateRangePickerProps> = ({
  startDate,
  endDate,
  focusedInput,
  setStartDate,
  setEndDate,
  setFocusedInput,
  preventPastDates,
}) => {
  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      // minDate={startDate}
      startDateId="rateplan-start-date"
      endDateId="rateplan-end-date"
      displayFormat="DD MMM, YYYY"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      isOutsideRange={preventPastDates ? undefined : () => false}
      onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
    />
  );
};

export default CustomDateRangePicker;
