import { LOGIN_SUCCESS } from "../actionTypes";

export const LoginSuccess = (auth: any) => {
    return (
        {
            type: LOGIN_SUCCESS,
            payload: auth
        }
    )
}
