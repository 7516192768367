import React, { useEffect } from "react";
import { LoginSuccess } from "../../redux/actions/LoginAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {AllProperties,CurrentProperty} from "../../redux/actions/PropertiesAction";
import kernelApi from "../../API/kernelApi";


const Loginwithoutcredential = () => {

  let dispatch = useDispatch();
  const navigate = useNavigate();
  let login_data:any = useParams();

    useEffect(() => {

        let send_login_data = {admin_id:atob(login_data.admin_id),auth_token:login_data.auth_token,company_id:atob(login_data.company_id)}
        dispatch(LoginSuccess(send_login_data));

        const { company_id, auth_token } = send_login_data ?? {};
        getAllHotels(company_id, auth_token);
        navigate("/");
 

    }, [login_data])



    const getAllHotels = async (company_id: any, auth_token: any) => {
      let get_all_hotels = await kernelApi.get(
        `/hotel_admin/get_hotel_list/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
  
      if (get_all_hotels && get_all_hotels.data.status === 1) {
        dispatch(AllProperties(get_all_hotels?.data?.data));
        dispatch(CurrentProperty(get_all_hotels?.data?.data[0]));
      }
    };


  return (
    <></>
  );
};

export default Loginwithoutcredential;
