import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// import bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

// Custom Style Sheet
import "./assets/css/styles.min.css";

import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

// import routes
import AuthRoutes from "./routes/AuthRoutes";
import AppRoutes from "./routes/AppRoutes";

import { PersistGate } from "redux-persist/es/integration/react";
import { persistor } from "./redux/store";

// Toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const isAuthorized = useSelector((state: RootState) => state.auth.auth_token);

  return (
    // <PersistGate loading={null} persistor={persistor}>
    <>
      <ToastContainer position="top-center" autoClose={1500} closeOnClick />
      <Router>{isAuthorized ? <AppRoutes /> : <AuthRoutes />}</Router>
      </>
    // </PersistGate>
  );
}

export default App;
