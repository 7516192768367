import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loginwithoutcredential from "../components/loginComponents/Loginwithoutcredential";
import LogInPage from "../pages/LoginPage";


const AuthRoutes = () => {
  return (
    <Routes>
      {/* Changes */}
      <Route path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name" element={<Loginwithoutcredential />} />
      {/* Changes */}
      
      <Route path="/login" element={<LogInPage />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
