import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
// import { CurrentProperty } from "../../redux/actions/PropertiesAction";
// import { EnableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import { Capitalize, Logout } from "../../UtilityFunctions";
import { CreateRipple } from "../../views/Buttons/NextButton";
import { CurrentProperty } from "../../redux/actions/PropertiesAction";
import { EnableSwitchPropertyScreen } from "../../redux/actions/PromptAction";

const ManagePropertyDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { property_data, current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [showManageDropdown, setShowManageDropdown] = useState(false);
  const [showPropertyDropdown, setShowPropertyDropdown] = useState(false);

  // useEffect(() => {
  //   if (!current_property) {
  //     navigate("/select-property");
  //   }
  // }, []);

  document.addEventListener("mouseup", function (e: any) {
    let trigger = document.getElementById("manage-dropdown-trigger");
    var container = document.getElementById("manage-dropdown");
    if (
      container &&
      !container.contains(e.target) &&
      trigger &&
      !trigger.contains(e.target)
    ) {
      setShowManageDropdown(false);
    }
  });

  return (
    <>
      <div className="profile-manage-wrapper">
        {/* {current_property && (
          <Link to="property-setup">
            <div
              className="header__hotel__name__wrapper"
              onClick={CreateRipple}>
              <i className="bi bi-gear-fill hotel__building__icon"></i>
              <div className="hotel--name--header">
                {Capitalize(current_property.hotel_name) +
                  ` (${current_property?.hotel_id})`}
              </div>
            </div>
          </Link>
        )} */}
        {/* <div className="message__icon__wrapper">
          <i className="bi bi-chat-left"></i>
        </div>
        <div className="notification__wrapper">
          <i className="bi bi-bell notification--icon"></i>
        </div> */}
        <div
          id="manage-dropdown-trigger"
          className="property__dropdown__wrapper"
          onClickCapture={CreateRipple}
          onClick={() => setShowManageDropdown(!showManageDropdown)}
        >
          <div className="text-center hotel--name">
            Manage
            <i className="bi bi-caret-down-fill hotel__name--dropdown"></i>
          </div>
        </div>
      </div>
      <div
        id="manage-dropdown"
        className={`dropdown__menu__manage__wrapper ${
          showManageDropdown ? "dropDropMenuShow" : "dropDropMenuHide"
        }`}
      >
        <div className="dropdown__menu_list">
          {/* <div className="mange__user__details">
            <div className="manager__name__label">
              <div className="manage__text">
                <span className="day--status--message">
                  {Capitalize(current_property?.hotel_name)}
                </span>
              </div>
              <div className="user__role">
                Hotel Id{" "}
                <span className="id--number">
                  {" "}
                  - {current_property?.hotel_id}
                </span>
              </div>
            </div>
          </div> */}

          <div className="hotel__manage__section">
            {/* <div
              className="manage__wrapper__items hotel__manage__hover"
              onClick={() => {
                navigate("manage-channels");
              }}
            >
              <div className="manage__wrapper__items--label">
                Manage Channel
              </div>
            </div> */}
            {/* <div
              className="manage__wrapper__items hotel__manage__hover"
              onClick={() => {
                navigate("manage-users");
              }}
            >
              <Link to="manage-users">
                <div
                  className="manage__wrapper__items--label"
                  onClick={() => setShowManageDropdown(false)}
                >
                  Manage Users
                </div>
              </Link>
            </div> */}

            {/* <Link to="manage-subscription">
              <div className="manage__wrapper__items hotel__manage__hover">
                <div className="manage__wrapper__items--label">
                  Manage Subscriptions
                </div>
              </div>
            </Link> */}

            {/* <div className="manage__wrapper__items hotel__manage__hover">
              <div className="manage__wrapper__items--label">Manage Users</div>
            </div> */}
          </div>
          {/* <div className="hr--line mt-0"></div>  */}

          <div className={`property-list-handle-height mt-3`}>
            {/* <Link to="add-new-property/property-type" target="_blank">
              <div className="add__new__property">
                Add New Property
                <div className="add--button--icon">
                  <i className="bi bi-plus-lg"></i>
                </div>
              </div>
            </Link> */}
            {/* <div className="hr--line mt-0"></div> */}
            <div
              className="switch__property__wrapper"
              onClick={() => setShowPropertyDropdown(!showPropertyDropdown)}
            >
              <div className="switch__property--label">Switch Property</div>
              <div className="switch__toggle__button">
                <i
                  className={`gg-chevron-${
                    showPropertyDropdown ? "down" : "right"
                  } toggle__switch__icon--color`}
                ></i>
              </div>
            </div>

            <div
              className={`property-overflow hide-scrollbar ${
                showPropertyDropdown ? "dropShow" : "dropHide"
              }`}
            >
              {property_data?.map((property: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      key={property?.hotel_id}
                      className="property__wrapper"
                      onClick={() => {
                        if (property?.hotel_id !== current_property?.hotel_id) {
                          dispatch(CurrentProperty(property));
                          dispatch(EnableSwitchPropertyScreen());
                        }
                        setShowManageDropdown(false);
                        setShowPropertyDropdown(false);
                      }}
                    >
                      <div className="property__name__icon">
                        <i className="gg-keyboard"></i>
                      </div>
                      <div className="property__name__wrapper">
                        <div className="property__name">
                          {Capitalize(property?.hotel_name)}
                        </div>
                        {/* <div className="property__address">{`${property?.city_name}, ${property?.state_name}`}</div> */}
                      </div>
                    </div>
                    {i !== property_data?.data?.length - 1 && (
                      <div className="hr--line my-0"></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            {/* <Link to="manage-users">
              <div
                className="add__new__property"
                onClick={() => setShowManageDropdown(false)}
              >
                Manage Users
              
              </div>
            </Link> */}

            <div
              className="hotel__logout hotel__manage__logout__hover"
              onClick={Logout}
            >
              <div className="manage__wrapper__items--label logout--label">
                <div className="logout--name">Logout</div>

                <div className="logout__icon">
                  <i className="bi bi-box-arrow-right logout-icon-size"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePropertyDropdown;
