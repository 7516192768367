import logo from "../assets/img/logoIcon.png";
import banner from "../assets/img/login_banner.png";

import LoginForm from "../components/loginComponents/LoginForm";

const LoginPage = () => {
  return (
    <div className="login-page-container">
      <div className="left-side">
        <div className="left-header">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="banner-wrapper">
          <img className="banner-pic" src={banner} alt="person" />
        </div>
      </div>
      <div className="right-side">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
