import React from "react";

import ManagePropertyDropdown from "../manageproperty/ManagePropertyDropdown";

const AppHeader = () => {
  return (
    <>
      <div className="header-wrapper header-bg-color header-position">
        <div className="header__content__wrapper">
          <ManagePropertyDropdown />
        </div>
      </div>
    </>
  );
};

export default AppHeader;
