import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DisableSwitchPropertyScreen } from '../redux/actions/PromptAction';
import { RootState } from '../redux/store';

import Loader from '../views/loader/Loader';

const SwitchProperty = () => {
    const dispatch = useDispatch();
    const { isSwitchingProperty } = useSelector((state: RootState) => state.prompt);

    useEffect(() => {
        let overlayEl = document.getElementById("overlay");
        if (isSwitchingProperty) {
            overlayEl?.classList.remove("d-none");
            overlayEl?.classList.add("animate__fadeIn");

            setTimeout(() => {
                overlayEl?.classList.remove("animate__fadeIn");
                overlayEl?.classList.add("animate__fadeOut");
                setTimeout(() => {
                    overlayEl?.classList.remove("animate__fadeOut");
                    overlayEl?.classList.add("d-none");
                    dispatch(DisableSwitchPropertyScreen());
                }, 1000);
            }, 5000);

        } else {
            setTimeout(() => {
                overlayEl?.classList.remove("animate__fadeIn");
                overlayEl?.classList.add("animate__fadeOut");
                setTimeout(() => {
                    overlayEl?.classList.remove("animate__fadeOut");
                    overlayEl?.classList.add("d-none");
                }, 1000);
            }, 2000);
        }
    }, [isSwitchingProperty]);

    return (
        <div id="overlay" className="standard__switch__property__wrapper animate__animated animate__faster d-none">
            <div className="switch__property__content__wrapper">
                <div className="div--head--label">Switching Property</div>
                <div className="div--helper--text mt-2">Please wait while we are switching property</div>
                <Loader />
            </div>
        </div>
    )
}

export default SwitchProperty;