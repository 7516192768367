import React from "react";

export interface ILoaderProps {
  className?: string;
}

const Loader: React.FC<ILoaderProps> = ({ className }) => {
  return (
    <div className={`div__loader__wrapper ${className}`}>
      <div className="login--loader mt-3"></div>
    </div>
  );
};

export default Loader;
