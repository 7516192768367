import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import LoginReducer from "./reducers/LoginReducer";
import PromptReducer from "./reducers/PromptReducer";
import PropertiesReducer from "./reducers/PropertiesReducer";
import SidebarReducer from "./reducers/SidebarReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "properties", "sidebar"],
};

const rootReducer = combineReducers({
  auth: LoginReducer,
  sidebar: SidebarReducer,
  properties: PropertiesReducer,
  prompt: PromptReducer,
});

export default persistReducer(persistConfig, rootReducer);
