import { LOGIN_SUCCESS } from "../actionTypes"

const initialState = {
    admin_id: null,
    auth_token: null,
    company_id: null,
}

const LoginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                admin_id: action.payload.admin_id,
                auth_token: action.payload.auth_token,
                company_id: action.payload.company_id,
            }
        default: return state
    }
}

export default LoginReducer